<template>
  <div class="page-body book-page">
    <h2>Book Jillybean</h2>

    <p
      v-for="scripture in scriptures"
      :key="scripture.reference"
      class="scripture"
    >
      <em class="scripture-verse">
        {{ scripture.text }}
      </em>
      <strong class="scripture-reference">
        {{ scripture.reference }}
      </strong>
    </p>
  </div>
</template>

<script>
    export default {
      name: 'BookPage',
      data () {
        return {
          scriptures: [
            // {
            //   text: "Remember this: Whoever sows sparingly will also reap sparingly, and whoever sows generously will also reap generously. Each of you should give what you have decided in your heart to give, not reluctantly or under compulsion, for God loves a cheerful giver.",
            //   reference: "2 Corinthians 9:6-7",
            // },
          ],
        };
      },
    };
</script>
