<template>
  <div
    id="branding"
    role="banner"
  >
    <router-link to="/home">
      <img
        alt
        role="presentation"
        src="../assets/header-image.png"
        class="img-responsive"
      >
    </router-link>
  </div>
</template>

<script>
    export default {
      name: 'Header',
    };
</script>
