<template>
  <div class="page-body">
    <h2>Women</h2>
    <div class="feature-image photo-with-caption">
      <img
        alt="Photo: 'Geriatric Jillybean'"
        role="presentation"
        class="img-responsive"
        src="../assets/women-feature.jpg"
      >
      <caption>'Geriatric Jillybean' takes the stage at Fellowship of Christian Magicians National Conference 2010</caption>
    </div>
    <p>Looking for that special speaker for a women’s retreat? Jill Bryan has been ministering to women and their families since 1983.</p>

    <p>Mother Daughter Banquets were the hit back then and still are a hit today. Jill has many topics to choose from and she adds comical life stories that will have your ladies doubling over with laughter.</p>

    <p>As a professional singer, speaker, &amp; comedian, Jill uses her talents to tell about the victories in life from painful situations. She is a cancer survivor and can relate to many from the loss in her own life. Her goal is to glorify God in all she does and to teach women about the power of forgiveness.</p>

    <p>Jill Bryan is a Titus 2 woman. Her desire is to teach the younger women and encourage all with the message of God’s love. After twenty five years of sharing the gospel worldwide the experiences get even richer everyday. All of us can be mighty women warriors for the King if we know how to put our armor on.</p>

    <p
      v-for="scripture in scriptures"
      :key="scripture.reference"
      class="scripture"
    >
      <em class="scripture-verse">
        {{ scripture.text }}
      </em>
      <strong class="scripture-reference">
        {{ scripture.reference }}
      </strong>
    </p>
  </div>
</template>

<script>
    export default {
      name: 'WomenPage',
      data () {
        return {
          scriptures: [
            {
              text: "Likewise, teach the older women to be reverent in the way they live, not to be slanderers or addicted to much wine, but to teach what is good. Then they can train the younger women to love their husbands and children, to be self-controlled and pure, to be busy at home, to be kind, and to be subject to their husbands, so that no one will malign the word of God.",
              reference: "Titus 2:3-5",
            },
            {
              text: "I have been crucified with Christ and I no longer live, but Christ lives in me. The life I live in the body, I live by faith in the Son of God, who loved me and gave himself for me.",
              reference: "Galatians 2:20",
            },
            {
              text: "For I know the plans I have for you,” declares the LORD, “plans to prosper you and not to harm you, plans to give you hope and a future.",
              reference: "Jeremiah 29:11",
            },
          ],
        };
      },
    };
</script>
