<template>
  <div class="page-body home-page">
    <div class="opening-section">
      <div class="welcome-text">
        <h2>Welcome</h2>
        <p>
          Hi and Welcome to Jillybean Enterprises! I have so much to tell you. All
          about Special programs for: Family Fun Nights, Missions Conferences,
          Children’s Events, Youth Events, Awana, Singles, Community Outreaches and
          Bikers. Or Just Plain FUN!!!
        </p>
        <p>
          Jillybean is a master storyteller and will keep you on the edge of your
          seat. Ventriloquism, puppetry and illusions are all part of a powerful
          Gospel message.
        </p>
      </div>
      <div class="welcome-image">
        <div class="feature-image photo-with-caption">
          <img
            alt="Photo: Jillybean with Ally Gator"
            role="presentation"
            class="img-responsive img-ally-and-jill"
            src="../assets/home-feature.jpg"
          >
          <caption>
            Jillybean with her friend Ally Gator
          </caption>
        </div>
      </div>
    </div>
    <p>
      <font-awesome-icon
        class="icon"
        :icon="['fab', 'youtube']"
      />&nbsp;
      <a
        href="https://www.youtube.com/channel/UCNOipdfE6yWdutdakSHeCQg/featured"
        target="_blank"
      >
        Jillybean's YouTube Channel
      </a>
    </p>
    <p>
      <font-awesome-icon
        class="icon"
        :icon="['fab', 'facebook']"
      />&nbsp;
      <a 
        href="https://www.facebook.com/chaplainJillBryan/" 
        target="_blank"
      >
        Jillybean's Facebook Page
      </a>
    </p>

    <h2>Catching up with Chaplain Jill</h2>
    <p>
      Wow! 40 years in ministry and until Jesus returns, we need to be ready and available to answer the call. I will share His love and trust Him always, especially in this topsy-turvy world where it's a challenge every day to find truth. <strong>God's Word IS the only truth we'll find</strong>.
    </p>
    <p>
      <img
        alt="Photo: Jillybean Promotional Poster"
        role="presentation"
        class="img-responsive img-homepage"
        src="../assets/img-homepage.png"
      >
    </p>
    <p>
      We have big plans for our online ministry with 
      <strong>
        <a 
          href="https://www.youtube.com/channel/UCNOipdfE6yWdutdakSHeCQg/featured"  
          target="_blank"
        >
          Cool Beans Productions
        </a>
      </strong> 
      as we add Bible devotionals for children. Our T-Shirts and products are available on Spreadshop at 
      <strong>
        <a 
          href="https://chaplain-jill-bryan.myspreadshop.com/" 
          target="_blank"
        >
          Chaplain Jill Marketplace
        </a>
      </strong>. Every purchase helps support the ministry. We will also have our first <strong>book available soon</strong>, which is an autobiography of my testimony and how God saved me.
    </p>
    <p>
      To my current and long-time supporters, I can't thank you enough for the years of prayer & support. If you're new to the ministry, please consider helping us go into the next decade to share the Gospel of Jesus Christ with everyone we meet. The ways you can help are many. You can 
      <router-link to="/contact">
        <strong>donate online</strong>
      </router-link> 
      here on our site, 
      <a 
        href="https://chaplain-jill-bryan.myspreadshop.com/" 
        target="_blank"
      >
        <strong>purchase products</strong>
      </a>
      or 
      <a 
        href="https://chaplain-jill-bryan.myspreadshop.com/" 
        target="_blank"
      >
        <strong>send a check to our new address</strong>
      </a>
      in Florida.
    </p>
    <p>
      Please pray for direction and wisdom as we move forward to reach others for the King. Thanks for partnering with me in ministry until He takes us home.
    </p>
    <p>
      Blessings,
    </p>
    <p class="signature">
      Chaplain Jill
    </p>
  </div>
</template>

<script>
    export default {
      name: 'Home',
    };
</script>
