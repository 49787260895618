import '../public/scss/default.scss';

import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';

import AboutPage from './components/PageAbout.vue';
import BookPage from './components/PageBook.vue';
import ChaplainJillPage from './components/PageChaplainJill.vue';
import ContactPage from './components/PageContact.vue';
import DonationsPage from './components/PageDonations.vue';
import HomePage from './components/PageHome.vue';
import KidsPage from './components/PageKids.vue';
import PonyExpressPage from './components/PagePonyExpress.vue';
import SchedulePage from './components/PageSchedule.vue';
import SinglesPage from './components/PageSingles.vue';
import WomenPage from './components/PageWomen.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faBible } from '@fortawesome/free-solid-svg-icons';
import { faYoutube, faFacebook, faAmazon } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faBible);
library.add(faBars);
library.add(faYoutube);
library.add(faFacebook);
library.add(faAmazon);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueRouter);

Vue.config.productionTip = false;

const routes = [
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage,
  },
  {
    path: '/book',
    name: 'BookPage',
    component: BookPage,
  },
  {
    path: '/chaplain-jill',
    name: 'ChaplainJillPage',
    component: ChaplainJillPage,
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage,
  },
  {
    path: '/donations',
    name: 'DonationsPage',
    component: DonationsPage,
  },
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/kids',
    name: 'KidsPage',
    component: KidsPage,
  },
  {
    path: '/pony-express',
    name: 'PonyExpressPage',
    component: PonyExpressPage,
  },
  {
    path: '/schedule',
    name: 'SchedulePage',
    component: SchedulePage,
  },
  {
    path: '/singles',
    name: 'SinglesPage',
    component: SinglesPage,
  },
  {
    path: '/women',
    name: 'WomenPage',
    component: WomenPage,
  },
  {
    path: '*',
    name: 'Default',
    component: HomePage,
  },
];

const router = new VueRouter({
  routes,
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
