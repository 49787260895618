<template>
  <div class="page-body">
    <h2>Who is Jillybean?</h2>
    <div class="feature-image photo-with-caption">
      <img
        alt="Photo: Jillybean with Ally Gator"
        role="presentation"
        class="img-responsive"
        src="../assets/about-feature-202101.jpg"
      >
      <caption>Jillybean is a master storyteller and will keep you on the edge of your seat. Ventriloquism, puppetry (as seen here with her friend Ally) and illusions are all part of a powerful Gospel message.</caption>
    </div>
    <p><strong>Jill Bryan</strong> <em>(AKA Jillybean)</em> communicates God&#8217;s truths with fun and laughter. Her message is one of abundant life offered in Jesus Christ. Jill was raised in a Christian home but she experienced life&#8217;s worst through her rebellious teen years and finally found herself broken and on her knees at 27 years of age.</p>
    <p>She found forgiveness and rededicated her life to God and His work. For almost 3 decades, Jill has performed gospel music and illusions &#8211; becoming known and loved around the world as &#8220;Jillybean.&#8221;</p>
    <p>Jillybean ministers to church groups worldwide and now is also a missionary to bikers and prisoners across America.</p>
    <h2>Jillybean&#8217;s Testimony</h2>
    <p><strong>I grew up in what I would call a Christian home.</strong> We went to church on Sundays and Wednesdays but somehow the home prayers were mostly at meals. My parents struggled with their relationship. I was two years old when they finally divorced. Mom had to raise three children on her own. She did the best she could while she worked two jobs. We moved to be closer to relatives and when I was eight years old I asked Jesus into my heart at a church camp. I began to study and memorize the Bible which, to this day I am very grateful for. But life had some very difficult twists and turns.</p>
    <p><strong>When I was twelve years old</strong>, a camp youth worker took advantage of me and stole my virginity. Although he continued seeing me, he lied to me saying that he would stay with me. Of course now I know how deceived I was. It was at this time I shook my fist at God and said &#8220;If this is Christianity, then I&#8217;ll have nothing to do with it.&#8221; I walked away from God and began to live for myself. At my age, I still had to go to church and look good for the family but as soon as I was able, I was out partying with my friends. I began doing drugs in seventh grade and hung out with the drinkers and smokers of the school. Because I was very outgoing, I could hide this double-life very well. I was involved in school and even became a cheerleader. I kept up my grades but still I partied very hard. I could have had any boyfriend I wanted and used people to please only me.</p>
    <p><strong>When I was nineteen I decided to marry and be taken care of.</strong> Or so I thought. After five months of marriage, I learned how wrong I was. My husband beat me beyond recognition. The doctors in the hospital couldn&#8217;t believe that I was alive. My husband even broke his hand hitting me. Because my mother was so against the idea of divorce, she begged me to stay with him and try to work it out. After four years, I couldn&#8217;t take any more. We divorced and I continued to try and find my own way.</p>
    <p><strong>I worked very hard and became a chef.</strong> In my spare time I was a drug dealer and very good at it, but after being in and out of jail on weekends I finally came to the end of my rope and cried out to God for help. I remember it as if it were yesterday. I was locked up inside of a jail cell, curled up in a little ball in the corner on the floor and said, &#8220;God if you&#8217;ll get me out of this, I&#8217;ll serve you the rest of my life.&#8221; Within thirty minutes I was out.</p>
    <p><strong>It took me one year later to realize what I had said to God,</strong> but on May 20, 1983, I surrendered my life to Jesus Christ. At the time I promised God I would serve Him, I really didn&#8217;t know how to, so I began doing Jillybean. Jillybean is the nickname that my brother used to call me. Now it&#8217;s a full-time ministry for kids and families worldwide. I thank God everyday for all He has done and will do in my life as I continue to serve Him. To God be the glory always and forever.</p>
    <h2>Kudos for Jillybean</h2>
    <p>&#8220;Jillybean is one of the most effective ministries in America . If you&#8217;ve grown old before your time, if you&#8217;ve wondered what Jesus meant when He told you to become a child or if you are just cynical and tired, Jillybean is an antidote from the Father&#8230;&#8221;</p>
    <p>-<strong> Steve Brown</strong>, President of Key Life Network, Inc.</p>
  </div>
</template>

<script>
    export default {
      name: 'AboutPage',
    };
</script>
