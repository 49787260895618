<template>
  <div class="page-body">
    <h2>Donations Page</h2>
    <p>This is an donations page.</p>
  </div>
</template>

<script>
    export default {
      name: 'DonationsPage',
    };
</script>
