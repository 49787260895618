<template>
  <div class="page-body">
    <h2>Singles</h2>
    <div class="feature-image photo-with-caption">
      <img
        alt="Photo: "
        role="presentation"
        class="img-responsive"
        src="../assets/singles-feature.jpg"
      >
      <caption>Jillybean with Aslan</caption>
    </div>
    <p>Jill’s dynamic testimony and spirit help her relate well with singles, helping them deal with peer pressure and decision making. Her favorite phrase “I like being single, REALLY”, and how she has overcome her trials will keep you laughing for hours.</p>
    <p>Through personal stories and scriptural application, Jill shares how the love of Jesus Christ applies to relationships and dating.</p>
    <p
      v-for="scripture in scriptures"
      :key="scripture.reference"
      class="scripture"
    >
      <em class="scripture-verse">
        {{ scripture.text }}
      </em>
      <strong class="scripture-reference">
        {{ scripture.reference }}
      </strong>
    </p>
  </div>
</template>

<script>
    export default {
      name: 'WomenPage',
      data () {
        return {
          scriptures: [
            {
              text: "Follow God’s example, therefore, as dearly loved children and walk in the way of love, just as Christ loved us and gave himself up for us as a fragrant offering and sacrifice to God.",
              reference: "Ephesians 5:1 & 2",
            },
            {
              text: "Now to the unmarried, widowers and the widows I say: It is good for them to stay unmarried, as I do.",
              reference: "1 Corinthians 7:8",
            },
            {
              text: "Therefore, since we have these promises, dear friends, let us purify ourselves from everything that contaminates body and spirit, perfecting holiness out of reverence for God.",
              reference: "2 Corinthians 7",
            },
          ],
        };
      },
    };
</script>
