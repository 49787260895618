<template>
  <div class="page-body chaplain-jill-page">
    <section>
      <h2>Chaplain Jill</h2>
      <p>Chaplain Jill is ready to minister to your organization with fun and laughter. Jill can teach how to be prepared for disasters and is especially gifted when it comes to working with youth.</p>

      <p>As a certified Chaplain, Jill is a member of the Federal Association of Christian Counselors and Therapists (FACCT), the International Conference of Police Chaplains (ICPC), Federation of Fire Chaplains (FFC) and International Critical Incident Stress Foundation (ICISF).</p>

      <p
        v-for="scripture in scriptures"
        :key="scripture.reference"
        class="scripture"
      >
        <em
          class="scripture-verse"
          v-html="scripture.text"
        />
        <strong
          class="scripture-reference"
          v-html="scripture.reference"
        />
      </p>
    </section>
  </div>
</template>

<script>
    export default {
      name: 'ChaplainJillPage',
      data () {
        return {
          scriptures: [
            {
              text: "In the same way, let your light shine before men, that they may see your good deeds and praise your Father in heaven.",
              reference: "Matthew 5:16",
            },
            {
              text: "A cheerful heart is good medicine, but a crushed spirit dries up the bones.",
              reference: "Proverbs 17:22",
            },
            {
              text: "If I speak in the tongues of men and of angels, but have not love, I am only a resounding gong or a clanging cymbal… Love is patient, love is kind. It does not envy, it does not boast, it is not proud… And now these three remain: faith, hope and love. But the greatest of these is love.",
              reference: `1 Corinthians 13 <a href="https://www.biblegateway.com/passage/?search=1%20Corinthians%2013&version=NIV" target="_blank">(Read Full Chapter)</a>`,
            },
          ],
        };
      },
    };
</script>
