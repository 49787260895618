<template>
  <div class="page-body contact-page">
    <section>
      <h2>Support Jillybean</h2>
      <p>Jillybean exists to share the gospel of Jesus Christ in fun and innovative ways. To that end, Jillybean partners with Christian and Mission organizations worldwide. Jillybean Enterprises is an international 501(c)(3) not-for-profit corporation supported through the tax-deductible donations of friends who believe in this ministry.</p>

      <h3 
        id="donations"
        class="donation-headline"
      >
        Donations
      </h3>
      <div class="donation-content contact-content-section">
        <p>
          To ensure that 100% of your donation goes directly to ministry, please send all donations to Chaplain Jill at:
        </p>
      
        <h4>Jillybean Enterprises, Inc.</h4>
        <p>
          9201 Morgan Glenn Dr<br>
          Mint Hill, NC 28227
        </p>

        <p class="text-center">
          Or donate online:
        </p>

        <p class="text-center">
          <a
            class="button"
            href="https://donate.stripe.com/aEUdTQ65q2rjfxm4gg"
            target="_blank"
          >
            Donate
          </a>
        </p>
      </div>

      <h3 class="contact-headline">
        Contact Information
      </h3>
      <div class="contact-content contact-content-section">
        <p>If you'd like to schedule Jillybean, please use the information below to contact her.</p>

        <h4>Jillybean Enterprises, Inc.</h4>
        <p>
          PO Box 5824<br>
          Rockford, IL 61125<br>
          Email: <a href="mailto:chaplainjillbryan@gmail.com?subject=Message%20from%20your%20Website">chaplainjillbryan@gmail.com</a>
        </p>

        <p>
          Chaplain Jill Bryan<br>
          Founder, CEO
        </p>
      </div>

      <h3 class="merchandise-headline">
        Merchandise
      </h3>
      <div class="merchandise-content contact-content-section">
        <div class="media-box">
          <div class="media-image image-container">
            <a
              href="https://chaplain-jill-bryan.myspreadshop.com/"
              target="_blank"
            >
              <img
                alt="Photo: T-Shirt"
                role="presentation"
                class="img-responsive merch-image"
                src="../assets/t-shirt.jpg"
              >
            </a>
          </div>
          <div class="media-text">
            <p>
              Jill has set up a store on 
              <a
                href="https://chaplain-jill-bryan.myspreadshop.com/"
                target="_blank"
              >
                Spreadshop
              </a>
              featuring fun graphics about Pony Express, Fruits of the Spirit, Armor of God and more! You can support Jill while customizing your own great-looking <strong>shirt</strong>, <strong>mug</strong>, <strong>tote bag</strong>, or <strong>sticker</strong>!
            </p>
            <p class="text-center">
              <a
                class="button"
                href="https://buy.stripe.com/aEUdTQbpK3vngBqdQR"
                target="_blank"
              >
                Buy Merchandise
              </a>
            </p>
          </div>
        </div>
      </div>

      <h3>Social Media</h3>
      <div class="social-content contact-content-section">
        <p>
          <font-awesome-icon 
            class="icon" 
            :icon="['fab', 'youtube']"
          />&nbsp;
          <a
            href="https://www.youtube.com/channel/UCNOipdfE6yWdutdakSHeCQg/featured"
            target="_blank"
          >Jillybean's YouTube Channel</a>
        </p>

        <p>
          <font-awesome-icon 
            class="icon" 
            :icon="['fab', 'facebook']"
          />&nbsp;
          <a
            href="https://www.facebook.com/chaplainJillBryan/"
            target="_blank"
          >Jillybean's Facebook Page</a>
        </p>

        <p
          v-for="scripture in scriptures"
          :key="scripture.reference"
          class="scripture"
        >
          <em class="scripture-verse">
            {{ scripture.text }}
          </em>
          <strong class="scripture-reference">
            {{ scripture.reference }}
          </strong>
        </p>
      </div>
    </section>

    <section class="recommended-book feature-image photo-with-caption">
      <h3>Recommended Book: <em>Where is Chloe</em></h3>
      <a
        href="https://buy.stripe.com/aEUdTQbpK3vngBqdQR"
        target="_blank"
        title="Click to Buy Where is Chloe?"
      >
        <img
          alt="Photo: Book - Where is Chloe by Donna A. Wilkins, M.D. and Nancy Munger (Link opens in new window)"
          class="img-responsive"
          src="../assets/wheres-chloe-book.jpg"
          title="Photo: Book - Where is Chloe by Donna A. Wilkins, M.D. and Nancy Munger (Link opens in new window)"
        >
      </a>

      <caption>
        <strong>Wow, what an awesome book!</strong><br>As a children's specialist, I would recommend this book for everyone. In fact, it should be in every hospital in America. I have used it in my chaplaincy work and also in performances as I travel. Recently I read it during a performance in Galveston, Texas. My friends all want this incredible book to share with families who have lost their children at an early age. Thank you, Dr. Wilkins, for writing <em>Where is Chloe?</em> and I'll continue to sing its praises wherever I go!<br> - <strong>Chaplain Jill Bryan</strong>
      </caption>

      <div class="purchase-information">
        <h3 class="purchase-book-headline">
          <em>Where is Chloe</em> is available from Chaplain Jill!
        </h3>
        <p>
          <a
            class="button"
            href="https://buy.stripe.com/aEUdTQbpK3vngBqdQR"
            target="_blank"
            title="Click to Buy Where is Chloe?"
          >
            Buy <em>Where Is Chloe?</em>
          </a>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
    export default {
      name: 'ContactPage',
      data () {
        return {
          scriptures: [
            {
              text: "Remember this: Whoever sows sparingly will also reap sparingly, and whoever sows generously will also reap generously. Each of you should give what you have decided in your heart to give, not reluctantly or under compulsion, for God loves a cheerful giver.",
              reference: "2 Corinthians 9:6-7",
            },
          ],
        };
      },
    };
</script>
