<template>
  <div class="page-body pony-express-page">
    <h2>Pony Express</h2>
    <p>
      Chaplain Jill loves teaching about the Pony Express in schools and home schools. Before there were telegraphs or telephones, the Pony Express rode their horses tirelessly from west to east and back again, delivering important messages in spite of dangers encountered. Contact Jill if you'd like her to speak at your school about this important part of America's history.
    </p>
    <p>
      For more information on the Pony Express, visit 
      <a 
        href="https://www.ponyexpress.org" 
        target="_blank"
      >The Pony Express National Museum</a> 
      or 
      <a 
        href="https://nationalponyexpress.org/" 
        target="_blank"
      >
        National Pony Express Association
      </a>
    </p>
    <section
      class="pony-express-container"
    >
      <blockquote 
        class="pony-express-oath"
        cite="https://www.ponyexpress.org/historical-notes#:~:text=Pony%20Express%20Oath,help%20me%20God.%E2%80%9D"
      >
        <p>I, _________, do hereby swear, before the Great and Living God, that during my engagement, and while an employee of Russell, Majors and Waddell, I will, under no circumstances, use profane language, that I will drink no intoxicating liquors, that I will not quarrel or fight with any other employee of the firm, and that in every respect I will conduct myself honestly, be faithful to my duties, and so direct all my acts as to win the confidence of my employers, so help me God.</p>
        <footer>—Alexander Majors, <cite>The Pony Express Oath</cite></footer>
      </blockquote>

      <img
        id="pony-express-hero"
        alt="Photo: 'Jill with Pony Express Riders'"
        role="presentation"
        class="img-responsive"
        src="../assets/pony-express-hero.jpg"
      >
    </section>
  </div>
</template>

<script>
    export default {
      name: 'PonyExpressPage',
      data () {
        return {
        };
      },
    };
</script>
