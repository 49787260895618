<template>
  <div class="page-body">
    <h2>Kids</h2>
    <div class="feature-image photo-with-caption">
      <img
        alt="Photo: "
        role="presentation"
        class="img-responsive"
        src="../assets/kids-feature.jpg"
      >
      <caption>Jillybean tells a story at a Fellowship of Christian Magicians National Conference</caption>
    </div>
    <p>Children - Energetic, encouraging and inspiring, Jill Bryan communicates God’s truths with fun and laughter. Always a favorite with children, Jillybean holds their attention while teaching about God’s love and how to live for Him everyday.</p>
    <p>Jillybean is a master storyteller and will keep you on the edge of your seat. Ventriloquism, puppetry and illusions are all part of a powerful gospel message.</p>

    <h3>Videos</h3>
    <p>
      For more great videos from Jillybean, visit the 
      <a
        href="https://www.youtube.com/channel/UCNOipdfE6yWdutdakSHeCQg/featured"
        target="_blank"
      >
        JillybeanEnterprises Channel on YouTube!
      </a>
    </p>

    <p
      v-for="scripture in scriptures"
      :key="scripture.reference"
      class="scripture"
    >
      <em class="scripture-verse">
        {{ scripture.text }}
      </em>
      <strong class="scripture-reference">
        {{ scripture.reference }}
      </strong>
    </p>
  </div>
</template>

<script>
    export default {
      name: 'KidsPage',
      data () {
        return {
          scriptures: [
            {
              text: "Children, obey your parents in the Lord, for this is right. 2 “Honor your father and mother”—which is the first commandment with a promise…",
              reference: "Ephesians 6:1 & 2",
            },
            {
              text: "Trust in the LORD with all your heart and lean not on your own understanding; in all your ways submit to him, and he will make your paths straight.",
              reference: "Proverbs 3:5 & 6",
            },
          ],
        };
      },
    };
</script>
