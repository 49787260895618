<template>
  <div
    id="app"
    class="container"
  >
    <Header />

    <Navigation
      class="mobile"
      :navigation-start-open="false"
    />

    <Navigation class="desktop" />

    <div class="content-area">
      <div class="container">
        <router-view />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
    import Navigation from './components/Navigation.vue';
    import Header from './components/Header.vue';
    import Footer from './components/Footer.vue';

    export default {
      name: 'App',
      components: {
        Header,
        Navigation,
        Footer,
      },
    };
</script>
