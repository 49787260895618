<template>
  <div
    class="main-navigation navigation"
    role="navigation"
  >
    <a
      class="menu-toggle"
      href="#"
      @click.prevent="navigationOpen = !navigationOpen"
    >
      <font-awesome-icon
        class="menu-toggle-icon icon"
        icon="bars"
      />&nbsp;Menu
    </a>
    <ul
      v-show="navigationOpen || navigationStartOpen"
      id="menu-topnav"
      class="menu"
    >
      <router-link
        tag="li"
        to="/home"
        @click.native="navigationOpen = false"
      >
        Home
      </router-link>
      <router-link
        tag="li"
        to="/kids"
        @click.native="navigationOpen = false"
      >
        Kids
      </router-link>
      <router-link
        tag="li"
        to="/women"
        @click.native="navigationOpen = false"
      >
        Women
      </router-link>
      <router-link
        tag="li"
        to="/singles"
        @click.native="navigationOpen = false"
      >
        Singles
      </router-link>
      <router-link
        tag="li"
        to="/chaplain-jill"
        @click.native="navigationOpen = false"
      >
        Chaplain Jill
      </router-link>
      <router-link
        tag="li"
        to="/pony-express"
        @click.native="navigationOpen = false"
      >
        Pony Express
      </router-link>
      <router-link
        tag="li"
        to="/about"
        @click.native="navigationOpen = false"
      >
        About Jillybean
      </router-link>
      <router-link
        class="submenu-header"
        tag="li"
        to="/contact"
        @click.native="navigationOpen = false"
        @mouseover.native="contactHover = true"
        @mouseleave.native="contactHover = false"
      >
        Support
        <ul
          v-if="enableSubmenu"
          class="sub-menu"
          :class="{'active': contactHover}"
        >
          <router-link
            tag="li"
            to="/schedule"
            @click.native="navigationOpen = false"
          >
            Schedule
          </router-link>
          <router-link
            tag="li"
            to="/book"
            @click.native="navigationOpen = false"
          >
            Book Jillybean
          </router-link>
          <router-link
            tag="li"
            to="/donations"
            @click.native="navigationOpen = false"
          >
            Donations:
            1 – 2 – 3
          </router-link>
        </ul>
      </router-link>
    </ul>
  </div>
</template>

<script>
    export default {
      name: 'Navigation',

      props: {
          navigationStartOpen: {
            type: Boolean,
            default: true
          },
      },

      data: function () {
        return {
          enableSubmenu: false,
          navigationOpen: false,
          contactHover: false,
        };
      },
    };
</script>
