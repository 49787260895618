<template>
  <div
    id="main-footer"
    class="footer"
  >
    <p>
      All content © Jillybean Enterprises, Inc.<br> All rights reserved.
    </p>
  </div>
</template>

<script>
    export default {
      name: 'Footer',
    };
</script>
